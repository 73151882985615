import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const Private2 = () => {
  let user = localStorage.getItem("user");
  if (user) {
    user = false;
  } else {
    user = true;
  }
  return user ? <Outlet /> : <Navigate to="/" />;
};

export default Private2;
