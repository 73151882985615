import "./App.css";
import Dashboard from "./Pages/Dashboard";
import History from "./Pages/History";
import Privacy from "./Pages/Privacy";
import Term from "./Pages/Term";
import { Routes, Route } from "react-router-dom";
import Private from "./Private/Private";
import Private2 from "./Private/Private2";
import Login from "./component/Login";
import Signup from "./component/Sinup";
function App() {
  return (
    <>
      <Routes>
        <Route element={<Private />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/history" element={<History />} />
          <Route path="/term" element={<Term />} />
          <Route path="/privacy" element={<Privacy />} />
        </Route>
        <Route element={<Private2 />}>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
