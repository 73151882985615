import React from "react";
import MainDashBoard from "../drawer/MainDashBoard";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Fade,
  Zoom,
} from "@mui/material";

const History = () => {
  const [fadeIn, setFadeIn] = React.useState(false);

  React.useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <MainDashBoard>
      <Box mt={2}>
        <Fade in={fadeIn} timeout={1000}>
          <Typography variant="h4" mb={2}>
            History Of Mobiloitte
          </Typography>
        </Fade>
        <List>
          <Zoom in={fadeIn} timeout={500}>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  <strong>1. Mobile App Development</strong>
                  <br />
                  They offer mobile application development services across
                  various platforms, such as iOS, Android, and hybrid
                  technologies.
                </Typography>
              </ListItemText>
            </ListItem>
          </Zoom>
          {/* Add Zoom component to each ListItemText */}
          <Zoom in={fadeIn} timeout={600}>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  <strong>2.Web Development</strong>
                  <br />
                  Mobiloitte provides web development services, including
                  front-end and back-end development, as well as full-stack
                  development.
                </Typography>
              </ListItemText>
            </ListItem>
          </Zoom>
          <Zoom in={fadeIn} timeout={700}>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  <strong>3.Blockchain Development</strong>
                  <br />
                  The company has expertise in blockchain technology and has
                  been involved in developing decentralized applications
                  (DApps), smart contracts, and other blockchain-based
                  solutions.
                </Typography>
              </ListItemText>
            </ListItem>
          </Zoom>
          <Zoom in={fadeIn} timeout={800}>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  <strong>4.IoT (Internet of Things)</strong>
                  <br />
                  Mobiloitte works on IoT solutions, connecting devices and
                  enabling data exchange for various industries.
                </Typography>
              </ListItemText>
            </ListItem>
          </Zoom>
          <Zoom in={fadeIn} timeout={900}>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  <strong>
                    5.Artificial Intelligence (AI) and Machine Learning (ML)
                  </strong>
                  <br />
                  They are involved in developing solutions that leverage AI and
                  ML technologies.
                </Typography>
              </ListItemText>
            </ListItem>
          </Zoom>
          <Zoom in={fadeIn} timeout={1000}>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  <strong>6.UI/UX Design</strong>
                  <br />
                  The company focuses on creating user-friendly and visually
                  appealing designs for websites and applications
                </Typography>
              </ListItemText>
            </ListItem>
          </Zoom>
          <Zoom in={fadeIn} timeout={1100}>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  <strong>7.Enterprise Solutions</strong>
                  <br />
                  Mobiloitte provides enterprise-level solutions and services to
                  businesses, including CRM (Customer Relationship Management)
                  and ERP (Enterprise Resource Planning) systems.
                </Typography>
              </ListItemText>
            </ListItem>
          </Zoom>
        </List>
      </Box>
    </MainDashBoard>
  );
};

export default History;
