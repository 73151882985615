import * as React from "react";
import { PieChart as MuiPieChart } from "@mui/x-charts/PieChart";
import { Typography, Box } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const data = [
  { Color: "green", id: 0, value: 10, label: "Active" },
  { id: 1, value: 15, label: "Hold", Color: "#f7df02" },
  { id: 2, value: 20, label: "Close", Color: "#0567a8" },
];

const CustomPieChart = ({ series, height }) => {
  const handleRender = (event) => {
    const { shape } = event;
    const { Color } = shape.getData();

    shape.attr("fill", Color);
  };

  return (
    <MuiPieChart
      series={[
        {
          data,
          highlightScope: { faded: "global", highlighted: "item" },
          event: { render: handleRender },
        },
      ]}
      height={height}
    />
  );
};

export default function PieArc() {
  return (
    <>
      <Box
        width="100%"
        borderRadius="borderRadius"
        sx={{ border: ".5px solid #f2f0f0" }}
      >
        <Box
          sx={{
            backgroundColor: "#f2f0f0",
            display: "flex",
            justifyContent: "space-between",
            borderTopLeftRadius: 2,
            borderTopRightRadius: 2,
            padding: 1.5,
          }}
        >
          <Box>
            <Typography
              fontSize={12}
              marginTop="2 rem"
              fontWeight={700}
              justifyContent="center"
            >
              ANALYTICS AUDIENCE METRICS
            </Typography>
          </Box>

          <Box display="flex">
            <MoreHorizIcon fontSize="medium" sx={{ color: "grey" }} />
          </Box>
        </Box>
        <br></br>
        <CustomPieChart height={180} minWidth={38} />
      </Box>
    </>
  );
}
