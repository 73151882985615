import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

export default function BasicBars() {
  const [isGraphVisible, setIsGraphVisible] = React.useState(true);
  const [isTableVisible, setIsTableVisible] = React.useState(true);

  const handleArrowClick = () => {
    setIsGraphVisible((prev) => !prev);
    setIsTableVisible((prev) => !prev);
  };
  return (
    <Paper
      justifyContent="start"
      marginRight={2}
      sx={{ border: ".5px solid #f2f0f0" }}
    >
      <Box
        sx={{
          backgroundColor: "#f2f0f0",
          display: "flex",
          justifyContent: "space-between",
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
          padding: 1.5,
        }}
      >
        <Box>
          <Typography fontSize={14} marginTop="2 rem" fontWeight={600}>
            COUNTRY STATS
          </Typography>
        </Box>
        <Box display="flex" justifyContent="between">
          <ArrowDownwardSharpIcon
            fontSize="medium"
            sx={{ color: "grey" }}
            onClick={handleArrowClick}
          />
          <FullscreenIcon fontSize="medium" sx={{ color: "grey" }} />
        </Box>
      </Box>
      {isGraphVisible && (
        <BarChart
          xAxis={[
            {
              scaleType: "band",
              data: ["Mon", "Tue", "Wed", "Thru", "Fri", "Sat"],
            },
          ]}
          series={[
            { data: [400, 200, 200, 400, 500, 600, 700] },
            { data: [100, 250, 300, 400, 260, 600, 700] },
          ]}
          minwidth={320}
          height={300}
        />
      )}
      {isTableVisible && (
        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Country</TableCell>
                <TableCell>Sessions</TableCell>
                <TableCell>Goals Rate</TableCell>
                <TableCell>Bounce Rate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Canada</TableCell>
                <TableCell>400</TableCell>
                <TableCell>80%</TableCell>
                <TableCell>10%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>India</TableCell>
                <TableCell>200</TableCell>
                <TableCell>50%</TableCell>
                <TableCell>20%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>UK</TableCell>
                <TableCell>200</TableCell>
                <TableCell>60%</TableCell>
                <TableCell>15%</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}
