import React from "react";
import Card0 from "../component/Card";
import Card1 from "../component/Card1";
import Card2 from "../component/Card2";
import PieArc from "../component/Piechart";
import BasicBars from "../component/Bargraph";
import { Grid } from "@mui/material";
import MainDashBoard from "../drawer/MainDashBoard";

const Dashboard = () => {
  return (
    <>
      <MainDashBoard>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Card0 />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card1 />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card2 />
          </Grid>
        </Grid>
        <br></br>
        <Grid container spacing={0}>
          <Grid xs={12} md={7.9} lg={7.9}>
            <BasicBars />
          </Grid>
          <Grid xs={12} md={0.4} lg={0.4}></Grid>
          <Grid xs={12} md={3.7} lg={3.7}>
            <PieArc />
          </Grid>
        </Grid>
      </MainDashBoard>
    </>
  );
};

export default Dashboard;
