import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const setData = () => {
    let data = { Email: email, Password: password };
    data = JSON.stringify(data);
    console.log("data stored in the localstorage");
    localStorage.setItem("user", data);

    let auth = localStorage.getItem("user");
    let signData = sessionStorage.getItem("user");
    // console.log(JSON.parse(auth).Email);
    if (JSON.parse(signData)) {
      if (
        JSON.parse(auth).Email === JSON.parse(signData).Email &&
        JSON.parse(auth).Password === JSON.parse(signData).Password
      ) {
        navigate("/");
      } else {
        window.alert("User not found");
      }
    } else {
      window.alert("Sinup First ");
    }
  };

  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Typography
          variant="h3"
          sx={{ backgroundColor: "purple", color: "white" }}
        >
          Login Page
        </Typography>

        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Box sx={{ marginTop: "50px", marginBottom: "30px" }}>
              <TextField
                id="email"
                label="Email Id"
                variant="standard"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                sx={{ width: "300px" }}
              />
              <br />
              <TextField
                id="password"
                label="Password"
                variant="standard"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                sx={{ width: "300px" }}
              />
            </Box>
            <Box sx={{ marginLeft: "-10px" }}>
              <Link to="/forgot" className="forgot">
                Forgot Password?
              </Link>
              <Button
                variant="outlined"
                onClick={setData}
                sx={{ marginLeft: "80px" }}
                size="medium"
              >
                Login
              </Button>
            </Box>
            <Box sx={{ margin: "20px" }}>
              <Box>
                <Typography>
                  Don't have an account?
                  <Button
                    variant="outlined"
                    sx={{ marginLeft: "20px" }}
                    size="medium"
                  >
                    <Link to="/signup" className="btnlink">
                      SignUp
                    </Link>
                  </Button>
                </Typography>
              </Box>
            </Box>

            <Typography color="gray">or</Typography>
            <Box>
              <Button variant="outlined">SignUp with Google</Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
