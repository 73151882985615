import React from "react";
import MainDashBoard from "../drawer/MainDashBoard";
import { Typography, Box, List, ListItem, ListItemText } from "@mui/material";

const Term = () => {
  return (
    <MainDashBoard>
      <Box mt={2}>
        <Typography variant="h4">Terms And Conditions</Typography>
        <List>
          <ListItem>
            <ListItemText>
              <Typography variant="body1">
                <strong>1. ASSENT & ACCEPTANCE</strong>
                <br />
                By using the Website, You warrant that You have read and
                reviewed these Terms of Service and that You agree to be bound
                by it. If You do not agree to be bound by these Terms of
                Service, please leave the Website immediately. The Owner only
                agrees to provide use of this Website and Services to You if You
                assent to these Terms of Service. Further, based on the Services
                obtained by a User, additional terms and conditions in respect
                of the specific Services may apply, which shall be deemed an
                agreement between the Users and the Owner.
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography variant="body1">
                <strong>2. ASSENT & ACCEPTANCE</strong>
                <br />
                By using the Website, You warrant that You have read and
                reviewed these Terms of Service and that You agree to be bound
                by it. If You do not agree to be bound by these Terms of
                Service, please leave the Website immediately. The Owner only
                agrees to provide use of this Website and Services to You if You
                assent to these Terms of Service. Further, based on the Services
                obtained by a User, additional terms and conditions in respect
                of the specific Services may apply, which shall be deemed an
                agreement between the Users and the Owner.
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography variant="body1">
                <strong>3. ABOUT THE SITE</strong>
                <br />
                The Website is an online store which carries out sale of the
                following learning courses. We reserve the right to refuse
                service or refuse to sell the products on the Website at our
                sole discretion to anyone for any reason at any time.
                <br />
                The Website does not screen or censor the users who register on
                and access the Website. You assume all risks associated with
                dealing with other users with whom you come in contact through
                the Website. You agree to use the Website only for lawful
                purposes without infringing the rights or restricting the use of
                this Website by any third party.
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <Typography variant="body1">
                <strong>4. PAYMENT & FEES</strong>
                <br />
                Should You register for any of the paid Services on this website
                or purchase any product or service on this website, You agree to
                pay Us the specific monetary amounts required for that product
                or those Services. These monetary amounts (“Fees”) will be
                described to You during Your account registration and/or
                confirmation process. The final amount required for payment will
                be shown to You immediately prior to purchase. Payment for any
                ongoing Services is billed automatically until You notify Us
                that You would like to terminate Your access to the Services.
                <br />
                We reserve the right to refuse service or refuse to sell the
                products on the Website at our sole discretion to anyone for any
                reason at any time.
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    </MainDashBoard>
  );
};

export default Term;
