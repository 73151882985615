import React from "react";
import MainDashBoard from "../drawer/MainDashBoard";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Fade,
} from "@mui/material";

const Privacy = () => {
  const [fadeIn, setFadeIn] = React.useState(false);

  React.useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <>
      <MainDashBoard>
        <Box mt={2}>
          <Fade in={fadeIn} timeout={1000}>
            <Typography variant="h4" mb={2}>
              Privacy Policy
            </Typography>
          </Fade>
          <List>
            <Fade in={fadeIn} timeout={500}>
              <ListItem>
                <ListItemText>
                  <Typography variant="body1">
                    <strong>1. GENERAL</strong>
                    <br />
                    This Website with the URL of www.mobiloitte.com
                    (“Website/Site”) is operated by Mobiloitte Technologies
                    (“We/Our/Us”). We are committed to protecting and respecting
                    your privacy. We do collect your personal information and
                    process your personal data in accordance with the IT Act,
                    2000 (21 of 2000) and other national and state laws which
                    relate to the processing of personal data. Please read the
                    following carefully to understand our views and practices
                    regarding your personal data.
                  </Typography>
                </ListItemText>
              </ListItem>
            </Fade>
            {/* Add Fade component to each ListItemText */}
            <Fade in={fadeIn} timeout={500}>
              <ListItem>
                <ListItemText>
                  <Typography variant="body1">
                    <strong>2.HOW WE COLLECT THE INFORMATION</strong>
                    <br />
                    From you directly and through this Site: We may collect
                    information through the Website when you visit. The data we
                    collect depends on the context of your interactions with our
                    Website.
                  </Typography>
                </ListItemText>
              </ListItem>
            </Fade>
            <Fade in={fadeIn} timeout={500}>
              <ListItem>
                <ListItemText>
                  <Typography variant="body1">
                    <strong>3.INFORMATION WE COLLECT</strong>
                    <br />
                    When you visit our Site, some information is automatically
                    collected. This may include information such as the
                    Operating Systems (OS) running on your device, Internet
                    Protocol (IP) address, access times, browser type, and
                    language, and the website you visited before our Site. We
                    also collect information about how you use Our products or
                    services.
                  </Typography>
                </ListItemText>
              </ListItem>
            </Fade>
            <Fade in={fadeIn} timeout={500}>
              <ListItem>
                <ListItemText>
                  <Typography variant="body1">
                    <strong>4.HOW WE USE INFORMATION</strong>
                    <br />
                    We may process your personal information without your
                    knowledge or consent where required by applicable law or
                    regulation for the purposes of verification of identity or
                    for prevention, detection, or investigation, including of
                    cyber incidents, prosecution, and punishment of offences.
                  </Typography>
                </ListItemText>
              </ListItem>
            </Fade>
            <Fade in={fadeIn} timeout={500}>
              <ListItem>
                <ListItemText>
                  <Typography variant="body1">
                    <strong>5.DATA TRANSFER</strong>
                    <br />
                    We share your data with your consent or to complete any
                    transaction or provide any product or service you have
                    requested or authorized. We also share data with our
                    affiliates and subsidiaries, with vendors working on our
                    behalf.
                  </Typography>
                </ListItemText>
              </ListItem>
            </Fade>
            <Fade in={fadeIn} timeout={500}>
              <ListItem>
                <ListItemText>
                  <Typography variant="body1">
                    <strong>6.COOKIES</strong>
                    <br />
                    The company focuses on creating user-friendly and visually
                    appealing designs for websites and applications
                  </Typography>
                </ListItemText>
              </ListItem>
            </Fade>
            <Fade in={fadeIn} timeout={500}>
              <ListItem>
                <ListItemText>
                  <Typography variant="body1">
                    <strong>7.DATA SECURITY</strong>
                    <br />
                    To optimize our web presence, we use cookies. These are
                    small text files stored on your computer’s main memory.
                    These cookies are deleted after you close the browser. Other
                    cookies remain on your computer (long-term cookies) and
                    permit its recognition on your next visit. This allows us to
                    improve your access to our site. This helps us to learn more
                    about your interests, and provide you with essential
                    features and services and for additional purposes,
                    including.
                  </Typography>
                </ListItemText>
              </ListItem>
            </Fade>
          </List>
        </Box>
      </MainDashBoard>
    </>
  );
};

export default Privacy;
