import { Typography, Box, TextField, Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";

const Signup = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  let data = { Email: email, Password: password };
  data = JSON.stringify(data);

  const setData = () => {
    sessionStorage.setItem("user", data);
    console.log("data store in session storage");
    navigate("/login");
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography
        variant="h3"
        sx={{ backgroundColor: "purple", color: "white" }}
      >
        SignUp Page
      </Typography>
      <Box>
        <TextField
          id="name"
          label="Name"
          variant="standard"
          sx={{ width: "300px" }}
        />
        <br />
        <TextField
          id="email"
          label="Email Id"
          variant="standard"
          sx={{ width: "300px" }}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <br />
        <TextField
          id="password"
          label="Password"
          variant="standard"
          sx={{ width: "300px" }}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </Box>
      <Box sx={{ margin: "20px" }}>
        <Button variant="outlined" onClick={setData}>
          SignUp
        </Button>
        {/* <Button variant="outlined" sx={{ marginLeft: "20px" }}>
          Login
        </Button> */}
      </Box>
    </Box>
  );
};

export default Signup;
