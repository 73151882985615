import React from "react";
import { Grid, Typography, Card, CardContent } from "@mui/material";

const Card0 = () => {
  return (
    <Card
      sx={{
        Width: "100%",
        background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
        position: "relative",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardContent sx={{ padding: 2 }}>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: { xs: 12, md: 14 },
                color: "white",
              }}
              gutterBottom
            >
              <Typography gutterBottom>Visitors</Typography>
              <Typography gutterBottom>+10%</Typography>
            </Typography>
            <Typography sx={{ fontSize: { xs: 18, md: 24 }, color: "white" }}>
              13.7K
            </Typography>

            <Typography sx={{ fontSize: { xs: 12, md: 13 }, color: "white" }}>
              172,458 Target Users
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Card0;
