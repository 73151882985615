import React from "react";
import { Grid, Typography, Card, CardContent } from "@mui/material";

const Card2 = () => {
  return (
    <Card
      sx={{
        Width: "100%",
        background:
          " linear-gradient(51deg, rgba(227,187,21,1) 2%, rgba(235,115,29,1) 49%, rgba(215,76,12,1) 75%)",
        position: "relative",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardContent sx={{ padding: 2 }}>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: { xs: 12, md: 14 },
                color: "white",
              }}
              gutterBottom
            >
              <Typography gutterBottom>Users</Typography>
              <Typography gutterBottom>+10%</Typography>
            </Typography>
            <Typography sx={{ fontSize: { xs: 18, md: 24 }, color: "white" }}>
              19M
            </Typography>

            <Typography sx={{ fontSize: { xs: 12, md: 13 }, color: "white" }}>
              172,458 Target Users
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Card2;
